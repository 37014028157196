<template>
	<div class="c-app flex-row align-items-center authen-page">
		<CContainer>
			<CRow class="justify-content-center">
				<CCol md="8">
					<CCardGroup>
						<CCard class="p-4">
							<CCardBody>
								<ValidationObserver ref="form" v-slot="{ handleSubmit }">
									<form @submit.prevent="handleSubmit(resetPwd)">
										<h1 class="pb-4 text-center">
											{{ $t("ForgotPwd.Title") }}
										</h1>
										<div class="form-group">
											<ValidationProvider
												v-slot="{ classes, errors }"
												name="auth_new_password"
												:rules="`requiredExport|max:${AUTH_ROLE.PASSWORD}|password`"
											>
												<div class="form-group row">
													<label for="newPassword" class="col-md-4 col-12 col-form-label">{{
														$t("ResetPwd.Password")
													}}</label>
													<div :class="['col-12 col-md-8', classes]">
														<div class="position-relative">
															<input
																v-model="resetForm.password"
																class="form-control"
																:placeholder="$t('ResetPwd.Password')"
																:type="showPwd ? 'password' : 'text'"
																autocomplete="curent-password"
																:maxlength="AUTH_ROLE.PASSWORD"
															/>
															<div v-if="resetForm.password" @click="showPassword">
																<span v-if="!showPwd" class="input-group-text icon-input">
																	<font-awesome-icon
																		:title="$t('ResetPwd.hidePwd')"
																		:icon="['fas', 'eye-slash']"
																	/>
																</span>
																<span v-else class="input-group-text icon-input">
																	<font-awesome-icon
																		:title="$t('ResetPwd.showPwd')"
																		:icon="['fas', 'eye']"
																	/>
																</span>
															</div>
														</div>
														<div class="error-mess">
															{{ errors[0] }}
														</div>
													</div>
												</div>
											</ValidationProvider>
										</div>

										<div class="form-group">
											<ValidationProvider
												v-slot="{ classes, errors }"
												name="auth_confirm_password"
												:rules="`requiredExport|max:${AUTH_ROLE.PASSWORD}|confirmedBy:@auth_new_password`"
											>
												<div class="form-group row">
													<label for="newPassword" class="col-md-4 col-12 col-form-label">{{
														$t("ResetPwd.ConfirmPassword")
													}}</label>
													<div :class="['col-12 col-md-8', classes]">
														<div class="position-relative">
															<input
																v-model="resetForm.passwordConfirm"
																class="form-control"
																:placeholder="$t('ResetPwd.ConfirmPassword')"
																:type="showConfirmPwd ? 'password' : 'text'"
																autocomplete="curent-password"
																:maxlength="AUTH_ROLE.PASSWORD"
															/>
															<div v-if="resetForm.passwordConfirm" @click="showConfirmPassword">
																<span v-if="!showConfirmPwd" class="input-group-text icon-input">
																	<font-awesome-icon
																		:title="$t('ResetPwd.hidePwd')"
																		:icon="['fas', 'eye-slash']"
																	/>
																</span>

																<span v-else class="input-group-text icon-input">
																	<font-awesome-icon
																		:title="$t('ResetPwd.showPwd')"
																		:icon="['fas', 'eye']"
																	/>
																</span>
															</div>
														</div>
														<div class="error-mess">
															{{ errors[0] }}
														</div>
													</div>
												</div>
											</ValidationProvider>
										</div>

										<CRow>
											<CCol col="12" class="text-center">
												<button type="submit" class="px-4 btn btn-outline-custom">
													{{ $t("ResetPwd.Finish") }}
												</button>
											</CCol>
										</CRow>
									</form>
								</ValidationObserver>
							</CCardBody>
						</CCard>
					</CCardGroup>
				</CCol>
			</CRow>
		</CContainer>
		<CustomModal
			v-if="isChangeSuccess"
			:is-show-close="false"
			:is-show-cancel="false"
			:submit-text="$t('Button.OK')"
			@submit-modal="gotoLogin"
		>
			<template #content>
				{{ $t("ChangePwd.Success") }}
			</template>
		</CustomModal>
	</div>
</template>

<script>
import { AUTH_ROLE } from "@/shared/plugins/constants"
import { RESET_PASSWORD, CHECK_TOKEN_VALID } from "../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapState } = createNamespacedHelpers("authentication")

export default {
	name: "ResetPassword",
	data() {
		return {
			showPwd: true,
			AUTH_ROLE,
			showConfirmPwd: true,
			resetForm: {
				password: null,
				passwordConfirm: null,
			},
			isReset: false,
			isChangeSuccess: false,
		}
	},
	computed: {
		...mapState({
			resetErr: "resetPwdErr",
			isValid: "resetTokenValid",
			msgErr: "tokenErr",
			isLoading: "isLoading",
			email: "email",
			verifyKey: "verifyKey",
		}),
	},
	watch: {
		resetErr(val) {
			this.$refs.form.setErrors(val)
		},
	},
	created() {
		this.isReset = this.$route.query.isReset || false
	},
	methods: {
		...mapActions({ RESET_PASSWORD, CHECK_TOKEN_VALID }),
		showPassword() {
			this.showPwd = !this.showPwd
		},
		showConfirmPassword() {
			this.showConfirmPwd = !this.showConfirmPwd
		},
		resetPwd: async function () {
			const params = {
				...this.resetForm,
				email: this.email,
				verifyKey: this.verifyKey,
			}
			await this.RESET_PASSWORD({ ...params })
			this.isChangeSuccess = true
			this.changeModalSuccess(true)
		},
		gotoLogin() {
			this.isChangeSuccess = false
			this.changeModalSuccess(false)
			this.$router.push("/login")
		},
		changeModalSuccess(status = false) {
			this.$store.commit("set", ["modalShow", status])
		},
	},
}
</script>
